import { asyncWrap, fetchAPI } from '@/api';
import { utils } from '@/helpers';
import { createEntryData, EntryData } from '@/legacy-types';
import { Store } from '@/types';
import { create } from 'zustand';

type DataType = EntryData<Store>;

type RetailStore = Store & { isBrandStore?: false };
type BrandStore = Store & { isBrandStore: true };

export type UserStoresState = {
	loaded: boolean;
	loading: boolean;
	userStores: DataType;

	retailStores: RetailStore[];
	brandStores: BrandStore[];

	fetchUserStores: (noCache?: boolean) => Promise<DataType>;

	// Get retail stores
	getRetailStores: (filters?: { showArchived?: boolean }) => RetailStore[];
	getRetailStore: (id: number | string) => RetailStore | undefined;
};

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 60 * 5000;

export const useUserStoresStore = create<UserStoresState>((set, get) => ({
	loaded: false,
	loading: true,
	userStores: { ids: [], entries: {} },
	brandStores: [],
	retailStores: [],

	fetchUserStores(noCache) {
		return asyncWrap(
			async () => {
				const cachedSettings = getCachedDataType();
				if (cachedSettings && !noCache) {
					const { userStores, brandStores, retailStores } = cachedSettings;
					set({
						userStores,
						brandStores,
						retailStores,
						loaded: true,
						loading: false,
					});
				}
				set({ loading: true });
				const { stores = [] } = (await fetchAPI<{ stores: Store[] }>('/stores/:uid/-1')) ?? { stores: [] };
				const brandStores: BrandStore[] = [];
				const retailStores: RetailStore[] = [];
				const userStores = createEntryData(stores ?? [], (store) => {
					if (store.isBrandStore) brandStores.push(store as BrandStore);
					else retailStores.push(store as RetailStore);
					return store;
				});
				cacheDataType({ userStores, brandStores, retailStores });
				set({ userStores, loaded: true, loading: false, brandStores, retailStores });
				return userStores;
			},
			{
				errorCallback() {
					set({ loading: false });
				},
			},
		) as Promise<DataType>;
	},

	// Returns retail stores sorted by ID in ascending order
	getRetailStores(filters) {
		if (!get().loaded) get().fetchUserStores();
		const { retailStores } = get();
		const filteredStores = retailStores.filter((store) => {
			if (filters?.showArchived) return true;
			return !store.archived;
		});
		return filteredStores.sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0));
	},

	getRetailStore(id) {
		if (!get().loaded) get().fetchUserStores();
		return get().retailStores.find((store) => `${store.id}` === `${id}`);
	},
}));

type DataTypeCache = {
	inserted: number;
	data: {
		userStores: DataType;
		brandStores: BrandStore[];
		retailStores: RetailStore[];
	};
};

const cacheDataType = (data: DataTypeCache['data']) => {
	const cache: DataTypeCache = {
		inserted: Date.now(),
		data,
	};

	const cacheKey = `${utils.uid ?? '-1'}:::UserStores}`;
	localStorage.setItem(cacheKey, JSON.stringify(cache));
};

const getCachedDataType = (): DataTypeCache['data'] | null => {
	const cacheKey = `${utils.uid ?? '-1'}:::UserStores}`;
	const cache = localStorage.getItem(cacheKey);
	if (!cache) return null;

	// Check if cache is valid (less than 1 minute old)
	const parsedCache: DataTypeCache = JSON.parse(cache);
	if (Date.now() - parsedCache.inserted > CACHE_DURATION) {
		return null;
	}

	return parsedCache.data;
};

export default useUserStoresStore;
