import { safeFetchAPI } from '@/api';
import { timeUtils, userUtils, utils } from '@/helpers';
import { ContactQueryResponse, FullContactQuery, FullPartial, TrimmedContact } from '@/types';
import { isEqual } from 'lodash';
import { create } from 'zustand';

const RAPID_CLICK_THRESHOLD = 3000; // 3 seconds window to check for rapid clicks
const FREE_REQUESTS = 5; // Number of requests allowed before cooldown kicks in
const BASE_COOLDOWN = 2000; // 2 second base cooldown once triggered
const MAX_COOLDOWN = 10000; // 10 seconds maximum cooldown
const COOLDOWN_INCREMENT = 2000; // Increment by 2 seconds each time
const COOLDOWN_RESET_TIME = 30000; // Reset cooldown after 30 seconds of no requests
const DEBOUNCE_DELAY = 100; // 25ms debounce delay

export type PersonasStoreV2State = {
	loaded: boolean;
	loading: boolean;
	lastFetch: number;
	lastFetchCooldown: number;
	recentRequests: number[]; // Timestamp array to track recent requests
	currentAbortController: AbortController | null;
	debounceTimer: NodeJS.Timeout | null;
	data: ContactQueryResponse;
	getContacts: (query: FullPartial<FullContactQuery>) => Promise<ContactQueryResponse | undefined>;
	// Helpers
	nextPage: () => void;
	previousPage: () => void;
	results: () => TrimmedContact[] | undefined;
	prevQuery: FullPartial<FullContactQuery['query']> | null;
	mergeQuery: (query: FullPartial<FullContactQuery>) => FullContactQuery;
};

export const usePersonaSearchV2Store = create<PersonasStoreV2State>((set, get) => ({
	loaded: false,
	loading: false,
	lastFetch: 0,
	lastFetchCooldown: 0,
	recentRequests: [],
	currentAbortController: null,
	debounceTimer: null,
	prevQuery: null,
	data: {
		contacts: [],
		pagination: {
			page: 0,
			pageSize: 0,
			startIndex: 0,
			endIndex: 0,
			resultsPages: 0,
			totalCount: 0,
			resultsCount: 0,
			totalPages: 0,
		},
		sort: {
			signupDate: {
				direction: 'desc',
				priority: 0,
			},
		},
	},
	results: () => {
		return get().data.contacts;
	},
	nextPage() {
		const { pagination } = get().data;
		if (pagination.page >= pagination.totalPages) return;
		const nextPage = pagination.page + 1;
		get().getContacts({ pagination: { page: nextPage } });
	},
	previousPage() {
		const { pagination } = get().data;
		if (pagination.page <= 1) return;
		const previousPage = pagination.page - 1;
		get().getContacts({ pagination: { page: previousPage } });
	},
	async getContacts(payload) {
		// Cancel any existing request
		const { currentAbortController } = get();
		if (currentAbortController) {
			currentAbortController.abort();
			console.log('Aborted existing request');
		}

		// Clear any existing debounce timer
		const { debounceTimer } = get();
		if (debounceTimer) {
			clearTimeout(debounceTimer);
			console.log('Cleared existing debounce timer');
		}

		// Create a new promise that will be resolved after the debounce delay
		return new Promise((resolve) => {
			const debounceTimer = setTimeout(async () => {
				const { lastFetch, lastFetchCooldown, recentRequests } = get();
				const now = Date.now();

				// Clean up old requests outside the threshold window
				const recentRequestsInWindow = recentRequests.filter((time) => now - time < RAPID_CLICK_THRESHOLD);

				// Add current request timestamp
				const updatedRequests = [...recentRequestsInWindow, now];

				// Only apply cooldown if we have more than FREE_REQUESTS requests in our window
				if (updatedRequests.length > FREE_REQUESTS) {
					const timeSinceLastFetch = now - lastFetch;

					// Reset cooldown if enough time has passed
					if (timeSinceLastFetch >= COOLDOWN_RESET_TIME) {
						set({
							lastFetchCooldown: 0,
							recentRequests: [],
						});
					} else if (timeSinceLastFetch < lastFetchCooldown) {
						// Apply cooldown if we're still within the cooldown period
						const timeRemaining = () => get().lastFetchCooldown - (Date.now() - get().lastFetch);
						utils.toaster?.addToast?.({
							message: () => {
								return `Please wait ${timeUtils.formatMilliseconds(timeRemaining())} before fetching again.`;
							},
							interval: 1000,
							duration: Math.max(Math.min(3000, timeRemaining() - 300), 1),
							type: 'warning',
							placement: 'top',
							toastKey: 'contact-fetch',
						});
						set({ recentRequests: updatedRequests });
						resolve(undefined);
						return;
					}
				}

				// Create new AbortController for this request
				const abortController = new AbortController();
				set({ loading: true, currentAbortController: abortController });

				try {
					const [data, error] = await safeFetchAPI<ContactQueryResponse>(`/piis/:uid?debug=${userUtils.isDebugging('piis') || utils.isLocal() ? 'true' : 'false'}`, {
						method: 'POST',
						payload: get().mergeQuery(payload),
						signal: abortController.signal,
					});

					if (error || !data) {
						set({ loading: false, currentAbortController: null });
						utils.toaster?.addToast?.({
							message: error,
							type: 'danger',
							placement: 'top',
						});
						resolve(undefined);
						return;
					}

					// Calculate new cooldown only if we're over the FREE_REQUESTS limit
					const newCooldown =
						updatedRequests.length > FREE_REQUESTS ? Math.min(BASE_COOLDOWN + COOLDOWN_INCREMENT * (updatedRequests.length - FREE_REQUESTS), MAX_COOLDOWN) : 0;

					set({
						data,
						loading: false,
						loaded: true,
						lastFetch: now,
						prevQuery: payload.query ?? null,
						recentRequests: updatedRequests,
						lastFetchCooldown: newCooldown,
						currentAbortController: null,
					});

					resolve(data);
				} catch (err) {
					// Only show error toast if it's not an abort error
					if (!(err instanceof DOMException && err.name === 'AbortError')) {
						utils.toaster?.addToast?.({
							message: 'Failed to fetch contacts',
							type: 'danger',
							placement: 'top',
						});
					}
					set({ loading: false, currentAbortController: null });
					resolve(undefined);
				}
			}, DEBOUNCE_DELAY);

			set({ debounceTimer });
		});
	},
	mergeQuery(req) {
		const { data, prevQuery } = get();

		const hasChangedQuery = !data.initial && !isEqual(prevQuery ?? {}, req.query ?? {});

		if (hasChangedQuery) {
			req.pagination = {
				page: 1,
				pageSize: 10,
			};
		}

		return {
			...data,
			initial: false,
			...req,
			query: {
				...data.query,
				...req.query,
			},
			pagination: {
				...data.pagination,
				...req.pagination,
			},
			contacts: [],
		} as FullContactQuery;
	},
}));
