import { utils } from '@/helpers';
import { QueueCustomer } from '@/legacy-types';
import { create } from 'zustand';

export type PersonaPageStoreState = {
	isViewingFromStaffApp: boolean;

	// Staff App
	storeID: string | undefined;
	formID: string | undefined;

	initialContact: Partial<QueueCustomer> | undefined;

	refreshTable?: number;

	setValue: (value: Partial<Omit<PersonaPageStoreState, 'setValue'>>) => void;
};

export const usePersonaPageStore = create<PersonaPageStoreState>((set, get) => ({
	isViewingFromStaffApp: false,
	storeID: utils.local.getLocal('aiq:staff-app-storeID-:uid') ?? undefined,
	formID: undefined,
	refreshTable: undefined,
	initialContact: undefined,
	setValue: (value) => set(value),
}));

export default usePersonaPageStore;
